import COS  from 'cos-js-sdk-v5'
import { validateNull } from '@/utils/validate'

var cos = new COS({
    //AppId: 1321880879,
    SecretId: "AKIDOfliYeYL1Xr34AVzOZYKrAKXcUAg09PV",
    SecretKey: "ITnw31pgS2UmtqeNE3gAW1NabQ5c1JOm",
});

export default function(file, callBack, pathname, imgw, imgh) {
    // 压缩前将file转换成img对象
    var readImg = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image()
            const reader = new FileReader()
            reader.onload = function(e) {
                img.src = e.target.result
            }
            reader.onerror = function(e) {
                reject(e)
            }
            reader.readAsDataURL(file)
            img.onload = function() {
                resolve(img)
            }
            img.onerror = function(e) {
                reject(e)
            }
        })
    }

    /**
     * 压缩图片
     *@param img 被压缩的img对象
     * @param type 压缩后转换的文件类型
     * @param mx 触发压缩的图片最大宽度限制
     * @param mh 触发压缩的图片最大高度限制
     */
    var compressImg = (img, type, imgw, imgh) => {
        return new Promise((resolve) => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        const { width: originWidth, height: originHeight } = img
        // 目标尺寸
        let targetWidth = imgw || originWidth
        let targetHeight = imgh || originHeight
        canvas.width = targetWidth
        canvas.height = targetHeight
        context.clearRect(0, 0, targetWidth, targetHeight)
        // 图片绘制
        context.drawImage(img, 0, 0, targetWidth, targetHeight)
        canvas.toBlob(function(blob) {
            resolve(blob)
        }, type || 'image/jpeg') })
    }

    async function upload(file, callBack, pathname, imgw, imgh) {
        const img = await readImg(file)
        const blob = await compressImg(img, 'image/jpeg', imgw, imgh)
    
        let date = new Date()
        let timestamp = date.getTime()
        if (validateNull(pathname)) {
            let path = "feedback/" + date.getFullYear() + "-" + (date.getMonth() + 1);
            let name = path + "/" + timestamp.toString() + ".jpg";
            pathname = name
        }
        cos.putObject({
            Bucket: 'handzone-1330275875',
            Region: 'ap-hongkong',
            Key: pathname,/* 必须 */
            StorageClass: 'STANDARD',
            Body: blob, // 上传文件对象
            onProgress: function (progressData) {
                console.log(JSON.stringify(progressData));
            }
        }, function (err, data) {
            console.log(err || data);
            if (data && data.statusCode == 200) {
                console.log("上传成功的图片链接为：" +  "https://handzone-1330275875.cos.ap-hongkong.myqcloud.com/"  + pathname)
                if (callBack) {
                    callBack(
                        "https://handzone-1330275875.cos.ap-hongkong.myqcloud.com/" + pathname
                    );

                }
            } else {
                if (callBack) {
                    
                    callBack("");
                }
            }
        });
    }

    upload(file, callBack, pathname, imgw, imgh)

}